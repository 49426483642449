// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-aop-js": () => import("./../../../src/pages/aop.js" /* webpackChunkName: "component---src-pages-aop-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-future-courses-js": () => import("./../../../src/pages/future_courses.js" /* webpackChunkName: "component---src-pages-future-courses-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-navigator-js": () => import("./../../../src/pages/navigator.js" /* webpackChunkName: "component---src-pages-navigator-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-publications-js": () => import("./../../../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-thankyoumuch-js": () => import("./../../../src/pages/thankyoumuch.js" /* webpackChunkName: "component---src-pages-thankyoumuch-js" */),
  "component---src-pages-west-122020-js": () => import("./../../../src/pages/west122020.js" /* webpackChunkName: "component---src-pages-west-122020-js" */),
  "component---src-pages-west-js": () => import("./../../../src/pages/west.js" /* webpackChunkName: "component---src-pages-west-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

